import React from 'react'
import Layout from '../components/layout'
import Slider from '../components/slider'
import { useStaticQuery, graphql } from 'gatsby'
import Header from '../components/header'

const MarketingPage = () => {
    const data = useStaticQuery(graphql`
        {
            allClientsJson {
                nodes {
                    id
                    image {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    title
                    link
                    category
                }
            }
        }
    `)
    const { nodes } = data.allClientsJson

    const filteredNodes = nodes.filter((node) => node.category === 'marketing')

    return (
        <Layout
            title="Social Hustle's Marketing Campaigns Portfolio"
            description={`Discover how My Social Hustle's marketing services can help you achieve your business goals. From social media to email marketing, we specialize in creating effective strategies that engage your audience and drive conversions.`}
        >
            <Slider nodes={filteredNodes} />
        </Layout>
    )
}

export default MarketingPage
